/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/accessible-emoji */
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react'
import styles from './offer-card.module.scss';
import BtnCustom from '../BtnCustom';
import { CardPricePROPS, ClientI } from '../../../../types-project/Client';
import userMobx from '../../../../mobx/user';

const CardOffer: FC<CardNewPricePROPS> = ({ isConnected = false,  type = 'brand', subscriptions = [] }) => {

    // eslint-disable-next-line no-restricted-globals
    const param = new URLSearchParams(location.search)
    const referralUrl = param!?.get('referral')

    
    if (subscriptions.length < 1) {
        return null
    }
    const [parentCode, setParentCode] = useState<string | undefined>(undefined)
    // block btn and display loader when user click on btn
    const [loadingPremiumReq, setLoadingPremiumReq] = useState<boolean>(false)
    const navigate = useNavigate()


    const { t } = useTranslation()
    const price = type === 'brand' || isConnected ? subscriptions[0]?.price : subscriptions[1]?.price
    const advantagesType = advantages.slice(0, (type === 'brand' ? (advantages.length - 3) : advantages.length))

    // current selected plan
    const currentPlan = (subscriptions ?? [])?.find((plan) => plan.type_company === type || (isConnected && plan.companies.filter((company) => company._id === userMobx.getUserInfos()?._company._id).length > 0))

    useEffect(() => {
        const referral = sessionStorage.getItem('referral' ?? '{}')

        if ((referral && Object.keys(referral ?? {}).length > 0) || referralUrl) {
            if (referral) {
                setParentCode(JSON.parse(referral))
            } else if (referralUrl) {
                setParentCode(referralUrl)
            }
        }
    }, [])

    const onSubscribe = (subscriptionId: string) => {
        if (userMobx.getUserInfos()) {
            setLoadingPremiumReq(true)
            userMobx.goPremium(subscriptionId, parentCode, (data) => {
                sessionStorage.removeItem('referral')
                window.location.href = data
            })
            setLoadingPremiumReq(false)
        } else {
            if (parentCode) {
                sessionStorage.setItem(
                    'referral',
                    JSON.stringify(parentCode)
                )
            }
            navigate('/login')
        }
    }

    return (
        <>
            <article className={styles['card-offer__article']}>
                <h3 dangerouslySetInnerHTML={{
                    __html: t('premium:price', { price: price, frequency: t(`premium:frequency.${currentPlan?.payment_frequency}`)}),
                }} className={`gradient-text ${styles['card-offer__article_price']}`} />
                <p dangerouslySetInnerHTML={{
                    __html: t('premium:offered-video', { number: currentPlan?.nb_videos ?? 1, frequency: t(`premium:frequency.${currentPlan?.payment_frequency}`) }),
                }} className={styles['card-offer__article_info1']} />
                <div className={styles['advantages__']}>
                    {advantagesType.map((advantage, k) => (
                        <motion.div
                            key={advantage.title}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            exit={{ opacity: 0, y: -50 }}
                            className={styles['checkmark']}>
                            <div className={styles['advantages__content']}>
                                <div className={styles['advantages__content_right']}>
                                    <h4>{advantage.title}</h4>
                                    <p>{advantage.desc}</p>
                                </div>
                                <i className="fa-solid fa-circle-check" />
                            </div>
                            {k !== advantagesType.length - 1 && (<hr className='my-3' />)}
                        </motion.div>))}
                </div>
                <div className={styles['offer_sponsorship__section']}>
                    <h5>{t('premium:sponsoring.title')}</h5>
                    <p>{t('premium:sponsoring.desc')}</p>
                    <input defaultValue={parentCode} placeholder={t('premium:sponsoring.placeholder')} onChange={(evt) => {
                        setParentCode(evt.target.value)
                    }} />
                </div>

                <BtnCustom
                    title={t('premium:subscribe-btn')}
                    loading={loadingPremiumReq}
                    onClick={() => {
                        if (currentPlan)
                            onSubscribe(currentPlan._id)
                    }}
                    mainColor="btn-violet"
                    className={`${styles['btn-subscribe']}`}
                />
                <span className={styles['advantages__info_']}>{t('premium:offer-without-engagement')}</span>
            </article>
            <p dangerouslySetInnerHTML={{
                __html: t('premium:more-info-card'),
            }} className={styles['card-offer__info_nb']} />
        </>
    )
}

type CardNewPricePROPS = {
    type: 'brand' | 'agency',
    subscriptions: Array<CardPricePROPS>,
    isConnected: boolean
}

export default CardOffer

const advantages = [
    {
        title: 'Deal Créateur en direct',
        desc: 'Sélectionnez vos créateurs favoris. Achetez leur contenu vidéo et amplifiez votre message (UGC / Influence)'
    },
    {
        title: 'Gestionnaire de Campagnes',
        desc: 'Briefez les créateurs et gérez vos stratégies d’achat. Collectez des candidatures spontanées (UGC / Influence)'
    },
    {
        title: 'Messagerie',
        desc: 'Échangez de manière instantanée avec plus de 16K créateurs '
    },
    {
        title: 'Gestion Collaborative',
        desc: 'Ajoutez vos équipes sur votre compte  et collaborez'
    },
    {
        title: 'Marque Blanche',
        desc: 'Obtenez un watermark neutre sur vos contenus et invitez vos clients sur votre interface'
    },
    {
        title: 'Multimarques',
        desc: 'Ajoutez autant de marques que vous le souhaitez !  '
    },
    {
        title: 'Account manager',
        desc: 'Une personne dédiée 6/7j pour vous assister sur la plateforme'
    }
]